import React, { useState, useCallback, useEffect } from "react";
import NavDropdown from "./NavDropdown/NavDropdown";
import { Logo } from "../../Logo/Logo";
import { LoginButtons } from "./NavDropdown/loginAction/LoginButtons";
import { IconChevronDown, IconClose, IconGlobe } from "../../svgIcons";
import { SearcherWidgets } from "../viewHeaders/SearcherWidgets";
import { usePageViewStyle } from "../../../hooks/index";
import NavDropdownHover from "./NavDropdown/NavDropdownHover";
import { pageViewStyle } from "../../../pageViewStyle";
export const Navigation = (props) => {
    const [dropdownData, setDropdownData] = useState();
    const [dropdownType, setDropdownType] = useState();
    const [activeMenu, setActiveMenu] = useState("");
    const [mouseOverDropdown, setMouseOverDropdown] = useState(false);
    const [mouseOverMenu, setMouseOverMenu] = useState(true);
    const [data, setData] = React.useState("");
    const { isLarge, isSmall, isMedium } = usePageViewStyle();
    const [largeView, setLargeView] = useState(false);
    useEffect(() => {
        setLargeView(isLarge);
    }, [isLarge]);
    useEffect(() => {
        if (!mouseOverDropdown && !mouseOverMenu) {
            setDropdownData(undefined);
            setActiveMenu("");
        }
    }, [mouseOverDropdown, mouseOverMenu]);
    const activeMenuHandler = useCallback((val, type) => useCallback((e) => {
        const body = document.getElementsByTagName("body")[0];
        e.preventDefault();
        setActiveMenu("");
        body.classList.remove("menu-active");
        body.classList.remove("search-active");
        setDropdownData(undefined);
        setMouseOverMenu(true);
        const triggerDropdown = () => setTimeout(() => {
            body.classList.add("menu-active");
            setActiveMenu(val);
            if (val === "language") {
                setDropdownData(props.widgets.language);
            }
            else {
                setDropdownData([...props.navigationItems].find((item) => item.title === val));
            }
            setDropdownType(type);
        }, 200);
        if (val !== "" && val !== activeMenu) {
            document
                .querySelector(".navDropdown.active")
                ?.classList.remove("active");
            triggerDropdown();
        }
    }, [activeMenu, type, val]), []);
    const handleCloseDropDown = useCallback(() => {
        document.getElementsByTagName("body")[0].classList.remove("mobile-nav-active");
    }, []);
    const handleMouseOut = useCallback(() => {
        if (!isLarge) {
            return;
        }
        setMouseOverMenu(false);
    }, [isLarge]);
    const handleMouseIn = useCallback(() => {
        setMouseOverMenu(false);
        setDropdownData(undefined);
    }, []);
    const handleOnMenuActive = (menuitem) => activeMenuHandler(menuitem, undefined);
    const iconElement = (language) => {
        if (language.currentIcon && !/english/i.test(language.currentIcon)) {
            return React.createElement("img", { alt: language.currentCulture, src: language.currentIcon, width: "16px", height: "16px" });
        }
        else {
            return IconGlobe();
        }
    };
    const renderButtons = useCallback(() => {
        const handleLanguage = () => {
            setDropdownData(props.widgets.language);
            setDropdownType("widget");
        };
        return largeView ?
            React.createElement(React.Fragment, null,
                React.createElement(SearcherWidgets, { searchItems: props.searchWidgetProps.searchItems, searchPlaceholder: props.searchPlaceholder }),
                React.createElement(LoginButtons, { activeMenu: activeMenu, activeMenuHandler: activeMenuHandler, navigationItems: props.navigationItems }),
                React.createElement("div", { className: "navigation__widget navigation__widget--lang languageText", onClick: handleLanguage },
                    iconElement(props.widgets.language),
                    props.widgets.language.currentCulture)) : React.createElement(LoginButtons, { activeMenu: activeMenu, activeMenuHandler: activeMenuHandler, navigationItems: props.navigationItems });
    }, [largeView, props.searchWidgetProps.searchItems, props.searchPlaceholder, props.widgets.language, props.navigationItems, activeMenu, activeMenuHandler]);
    const clickHandler = React.useCallback((e) => {
        const currentView = pageViewStyle.getView();
        const mustTriggerSearch = currentView === "small" && e.key === "Enter";
        if (mustTriggerSearch) {
            window.open(`${window.location.origin}/${window.location.href.split("/")[3]}/search-results?searchterms=${data}`, "_self", "noreferrer");
        }
        e.key === "Enter" && console.warn(data);
    }, [data]);
    const searchFieldHandler = React.useCallback(e => setData(e.target.value), []);
    return (React.createElement("div", { className: "navigation__container" },
        React.createElement(Logo, { logoHref: props.logoHref ? props.logoHref : "/", imageUrl: props.imageUrl ? props.imageUrl : undefined, imageUrlReverse: props.imageUrlReverse ? props.imageUrlReverse : undefined }),
        React.createElement("div", { className: "navigation__list" },
            React.createElement("div", { className: "navigation__menuWrapper" },
                React.createElement("div", { className: "navigation__widgets__inputWrapper navigation__widgets__inputWrapper--mobile" },
                    React.createElement("input", { className: "navigation__search active", type: "search", placeholder: "Search site", onKeyDown: clickHandler, onChange: searchFieldHandler, value: data }),
                    React.createElement("a", { className: "clear-search", onClick: handleCloseDropDown }, IconClose())),
                React.createElement("div", { className: "navigation__menuTitleWrapper" },
                    React.createElement("p", { className: "navigation__menuTitleWrapper__menuTitle" }, props.menuTitle),
                    React.createElement("a", { onClick: handleCloseDropDown }, IconClose())),
                props.navigationItems.length > 0 &&
                    props.navigationItems.map((item, i) => item.type === "text" && (React.createElement("div", { key: `navigation-menu__item--${i}`, className: `navigation__item ${activeMenu === item.title
                            ? "navigation__item--current"
                            : ""}`, onMouseEnter: handleMouseIn },
                        React.createElement("span", { className: "navigation__link", onClick: (isSmall || isMedium) ? handleOnMenuActive(item.title) : undefined, onMouseLeave: handleMouseOut },
                            item.title,
                            IconChevronDown({ fill: "#0E1D31" })),
                        isLarge && React.createElement(NavDropdownHover, { click: activeMenuHandler("", undefined), dropdownType: item.type, data: item })))))),
        React.createElement("div", { className: "loginAndSearchWrapper" }, renderButtons()),
        React.createElement(NavDropdown, { click: activeMenuHandler("", undefined), dropdownType: dropdownType, data: dropdownData, mouseOver: setMouseOverDropdown })));
};
